import { defineStore } from 'pinia'
import type { CallsPhoneNumbers, CallsTimeSlots, ContentPageVersion } from '~/api'
import { callsApi } from '~/api'

export const useMainStore = defineStore('main', {
  state: () => ({
    interactionsSended: false,
    utmArr: undefined as object[] | undefined,
    wrongAddress: false,
    metrikaId: undefined as string | undefined,
    uaId: undefined as string | undefined,
    providerId: undefined as string | undefined,
    domain: '' as string,
    mainDomain: '' as string,
    reqHeaders: undefined as object | undefined,
    domainConfig: null as object | null,
    oldClient: false,
    providerClient: undefined as string | number | undefined,
    addressWOTariffs: false,
    segmentation: false,
    diProvider: false,
    disabledPortal: false,
    disabledMobile: false,
    version: null as ContentPageVersion | null,
    siteVersion: null as string | null,
    configShowMap: false,
    automation: null as boolean | null,
    quizBtnShow: true,
    btn60Show: false,
    archievedTariffs: false,
    callCenterBusy: false,
    errorState: null as object | null,
    showLoader: false,
    loaderText: undefined as object[] | undefined,
    loaderTime: undefined as number | undefined,
    commonHeaders: {},
    woFilter: false,
    woConsult: false,
    automatizationDirect: false,
    reverseAutomatizationTabs: false,
    showGreenBtn: false,
    callNumber: null as CallsPhoneNumbers | null,
    callSlots: null as CallsTimeSlots | null,
    clientIp: undefined as string | undefined,
    timeStamp: [] as any[],
    userAuth: null as any | null,
    saWStreets: false,
    showFast: false,
  }),
  actions: {
    async fetchPhoneNumber(providerId?: number) {
      try {
        this.callNumber = (
          await callsApi.getCallsGeneral(
            { providerId, domain: this.mainDomain },
            {
              headers: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                common: this.commonHeaders,
              },
            },
          )
        ).data
      }
      catch (e) {
        this.callNumber = (
          await callsApi.getCallsGeneral(
            { providerId, domain: this.mainDomain },
            {
              headers: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                common: this.commonHeaders,
              },
            },
          )
        ).data
      }
    },
    async fetchCallSlots() {
      const timezoneOffset = new Date().getTimezoneOffset()
      try {
        this.callSlots = (await callsApi.getTimeSlots({ timezoneOffset })).data
      }
      catch (e) {}
    },
    async getCallCenterStatus() {
      const res = (await callsApi.getCallsStatus(
        {},
        {
          headers: {
            common: this.commonHeaders,
          },
        })).data
      this.callCenterBusy = res.availability !== 'AVAILABLE'
    },
  },
  getters: {
    getInteractionsSended: state => state.interactionsSended,
    getUtmArr: state => state.utmArr,
    getWrongAddress: state => state.wrongAddress,
    getMetrikaId: state => state.metrikaId,
    getUaId: state => state.uaId,
    getProviderId: state => state.providerId,
    getDomain: state => state.domain,
    getMainDomain: state => state.mainDomain,
    getReqHeaders: state => state.reqHeaders,
    getDomainConfig: state => state.domainConfig,
    getOldClient: state => state.oldClient,
    getProviderClient: state => state.providerClient,
    getAddressWOTariffs: state => state.addressWOTariffs,
    getSegmentationActive: state => state.addressWOTariffs || state.oldClient,
    getSegmentation: state => state.segmentation,
    getDiProvider: state => state.diProvider,
    getVersion: state => state.version,
    getSiteVersion: state => state.siteVersion,
    getConfigShowMap: state => state.configShowMap,
    getAutomation: state => state.automation,
    getQuizBtnShow: state => state.quizBtnShow,
    getBtn60Show: state => state.btn60Show && !state.archievedTariffs && !state.callCenterBusy,
    getCommonHeaders: state => state.commonHeaders,
    getWoConsult: state => state.woConsult,
    getAutomatizationDirect: state => state.automatizationDirect,
    getReverseAutomatizationTabs: state => state.reverseAutomatizationTabs,
    getShowGreenBtn: state => state.showGreenBtn,
    getWoFilter: state => state.woFilter,
    getLoader: state => state.showLoader,
    getLoaderText: state => state.loaderText,
    getLoaderTime: state => state.loaderTime,
    getClientIp: state => state.clientIp,
    getErrorState: state => state.errorState,
    getCallNumber: state => state.callNumber,
    getCallSlots: state => state.callSlots,
    getUserAuth: state => state.userAuth,
    getSaWStreets: state => state.saWStreets,
    getShowFast: state => state.showFast,
  },
})
