import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt3_plugin_BdfRvWAAOY from "/app/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import plugin_t2GMTTFnMT from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import nuxt_plugin_6wEQMY3tee from "/app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_eTVJQYlCmx from "/app/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import digitsonly_client_JlKrdXvnR4 from "/app/plugins/digitsonly.client.ts";
import eventBus_P6CbRuQa3W from "/app/plugins/eventBus.ts";
import getCity_client_zTdapkWBdj from "/app/plugins/getCity.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import thumbor_UpGnkcp3IL from "/app/plugins/thumbor.ts";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt3_plugin_BdfRvWAAOY,
  plugin_t2GMTTFnMT,
  nuxt_plugin_6wEQMY3tee,
  plugin_tbFNToZNim,
  plugin_eTVJQYlCmx,
  unocss_MzCDxu9LMj,
  digitsonly_client_JlKrdXvnR4,
  eventBus_P6CbRuQa3W,
  getCity_client_zTdapkWBdj,
  sentry_client_shVUlIjFLk,
  thumbor_UpGnkcp3IL,
  vuetify_7h9QAQEssH
]